<template>
  <div>
    <div class="page-content border-bottom">
      <!-- 顶部搜索框 -->
      <!-- <div>
        <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
          <el-form-item label="城市">
            <el-input
              v-model="searchInfo.number"
              placeholder="请输入城市"
              class="form-item-width"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="searchInfo.number"
              placeholder="请输入手机号"
              class="form-item-width"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item class="right-btns">
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onReset">重置</el-button>
          </el-form-item>
        </el-form>
      </div> -->
    </div>
    <div class="page-content margin-top">
      <!-- 表格组件 -->
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
      >
        <!-- 中间字段 -->
        <el-table-column label="序号" width="70" align="left">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          v-for="tab in tableTitle"
          :key="tab.code"
          :label="tab.name"
          show-overflow-tooltip
          :width="tab.width"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[tab.code] }} </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 弹框组件 -->
      <!-- <DateEdit ref="edit" /> -->
    </div>
  </div>
</template>

<script>
import { getDetailTabDataFun, getFraudTypeListFun } from "@/api/datadetail";
import { getAssignmentList } from "../../api/common";

export default {
  components: {
    // Pagination,
    // DateEdit,
  },
  data() {
    return {
      // 诈骗时间
      datePickerVal: [],
      // 多选选中的数据
      selectList: [],
      // 诈骗类型数组
      fraudTypeList: [],
      // 查询数据
      searchInfo: {
        startTime: "",
        endTime: "",
        city: "",
        // page: 1,
        // size: 10,
      },
      // 表格数据
      tableData: [],
      // 表格标题数组
      tableTitle: [
        { name: "Id", code: "id", width: "" },
        { name: "所在城市", code: "city", width: "" },
        { name: "车型", code: "model", width: "" },
        { name: "上牌时间", code: "registrationTime", width: "" },
        { name: "手机号码", code: "phone", width: "" },
        { name: "线索下发时间", code: "issueTime", width: "" },
        { name: "首次联系时间", code: "firstContactTime", width: "" },
        // { name: "联系状态", code: "contactState", width: "" },
      ],
      // 数据总数
      dataTotal: 100,
    };
  },
  mounted() {
    // 获取诈骗类型
    this.getFraudTypeLis();
    // 查询
    this.onGetDataList();
    this.getTicketsList();
  },
  methods: {
    // 获取诈骗类型
    getFraudTypeLis() {
      getFraudTypeListFun().then((result) => {
        this.fraudTypeList = result;
      });
    },
    //获取工单管理列表信息
    getTicketsList() {
      getAssignmentList().then((res) => {
        this.tableData = res.data.queryResult.list;
        this.tableData.forEach((item) => {
          item.phone = item.phone
            ? item.phone.slice(0, 3) + "****" + item.phone.slice(7, 11)
            : "——";
          item.firstContactTime = item.firstContactTime
            ? item.firstContactTime
            : "———";
        });
      });
    },
    // 修改诈骗时间日期框
    handleChangeDatePicker(e) {
      this.searchInfo.startTime = e[0];
      this.searchInfo.endTime = e[1];
    },
    // 多选
    handleSelectionChange(list) {
      this.selectList = list;
    },
    // 下载方法
    onDownload() {
      console.log(this.selectList);
    },
    // 查询
    onSearch() {
      // this.searchInfo.page = 1;
      this.onGetDataList();
    },
    // 重置
    onReset() {
      this.datePickerVal = [];
      this.searchInfo.startTime = "";
      this.searchInfo.endTime = "";
      this.searchInfo.fraudType = "";
      this.onSearch();
    },
    // 分页方法
    // onChangePages(pageInfo) {
    // const { page, limit } = pageInfo;
    // this.searchInfo.page = page;
    // this.searchInfo.size = limit;
    // this.onGetDataList();
    // },
    // 核心查询数据方法
    onGetDataList() {
      // 查询数据方法
      getDetailTabDataFun(this.searchInfo).then((result) => {
        console.log(result);
        this.tableData = result;
      });
    },
    // 查看数据详情
    examineRow(row) {
      console.log(row);
      this.$refs.edit.showDialog();
    },
  },
};
</script>

<style lang="less" scoped>
.form-item-width {
  width: 250px;
}
.right-btns {
  position: absolute;
  right: 20px !important;
}
.el-form-item {
  margin-bottom: 4px !important;
}
.border-bottom {
  border-bottom: 1px solid #e2e2e4;
}
.margin-top {
  margin-top: -15px;
}
.update-btn {
  cursor: pointer;
  color: #409eff;
}
</style>
